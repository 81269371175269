<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="时段名称" prop="taskName" >
        <a-input v-model="form.taskName" placeholder="请输入时段名称" />
      </a-form-model-item>
      <a-form-model-item label="作业类型" prop="taskType" >
        <a-select placeholder="请选择作业类型" v-model="form.taskType">
          <a-select-option v-for="(d, index) in taskTypeOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="作业开始时间" prop="taskStartTime" >
        <a-date-picker style="width: 100%" v-model="form.taskStartTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="作业结束时间" prop="taskEndTime" >
        <a-date-picker style="width: 100%" v-model="form.taskEndTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="作业趟数" prop="taskCount" >
        <a-input v-model="form.taskCount" placeholder="请输入作业趟数" />
      </a-form-model-item>
      <a-form-model-item label="生效开始时间" prop="takeEffectStartTime" >
        <a-date-picker style="width: 100%" v-model="form.takeEffectStartTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="生效结束时间" prop="takeEffectEndTime" >
        <a-date-picker style="width: 100%" v-model="form.takeEffectEndTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="是否生效" prop="takeEffectState" >
        <a-select placeholder="请选择是否生效" v-model="form.takeEffectState">
          <a-select-option v-for="(d, index) in takeEffectStateOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否删除" prop="status" >
        <a-select placeholder="请选择是否删除" v-model="form.status">
          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleTaskRule, addVehicleTaskRule, updateVehicleTaskRule } from '@/api/iot/vehicleTaskRule'

export default {
  name: 'CreateForm',
  props: {
    taskTypeOptions: {
      type: Array,
      required: true
    },
    takeEffectStateOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        taskName: null,
        taskType: null,
        taskStartTime: null,
        taskEndTime: null,
        taskCount: null,
        takeEffectStartTime: null,
        takeEffectEndTime: null,
        takeEffectState: 0,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        taskName: [
          { required: true, message: '时段名称不能为空', trigger: 'blur' }
        ],
        taskType: [
          { required: true, message: '作业类型不能为空', trigger: 'change' }
        ],
        taskStartTime: [
          { required: true, message: '作业开始时间不能为空', trigger: 'blur' }
        ],
        taskEndTime: [
          { required: true, message: '作业结束时间不能为空', trigger: 'blur' }
        ],
        takeEffectStartTime: [
          { required: true, message: '生效开始时间不能为空', trigger: 'blur' }
        ],
        takeEffectEndTime: [
          { required: true, message: '生效结束时间不能为空', trigger: 'blur' }
        ],
        takeEffectState: [
          { required: true, message: '是否生效不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        taskName: null,
        taskType: null,
        taskStartTime: null,
        taskEndTime: null,
        taskCount: null,
        takeEffectStartTime: null,
        takeEffectEndTime: null,
        takeEffectState: 0,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleTaskRule(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleTaskRule(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleTaskRule(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
