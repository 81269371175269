import request from '@/utils/request'

// 查询作业规则 列表
export function listVehicleTaskRule (query) {
  return request({
    url: '/iot/vehicleTaskRule/list',
    method: 'get',
    params: query
  })
}

// 查询作业规则 详细
export function getVehicleTaskRule (id) {
  return request({
    url: '/iot/vehicleTaskRule/' + id,
    method: 'get'
  })
}

// 新增作业规则
export function addVehicleTaskRule (data) {
  return request({
    url: '/iot/vehicleTaskRule',
    method: 'post',
    data: data
  })
}

// 修改作业规则
export function updateVehicleTaskRule (data) {
  return request({
    url: '/iot/vehicleTaskRule',
    method: 'put',
    data: data
  })
}

// 删除作业规则
export function delVehicleTaskRule (id) {
  return request({
    url: '/iot/vehicleTaskRule/' + id,
    method: 'delete'
  })
}

// 导出作业规则
export function exportVehicleTaskRule (query) {
  return request({
    url: '/iot/vehicleTaskRule/export',
    method: 'get',
    params: query
  })
}
